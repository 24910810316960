import BootcampForm from 'views/BootcampForm/BootcampForm';
import EventsView from '../views/Events/EventsView';
import Home from '../views/Home/Home';

const routes = [
  {
    path: '/',
    label: 'Fork',
    component: <Home />,
    displayOnMenu: true,
  },
  {
    path: '/events',
    label: 'Events',
    component: <EventsView />,
    displayOnMenu: true,
  },
  {
    path: '/bootcamp-register',
    label: 'Bootcamp Desarrollo Web',
    component: <BootcampForm />,
    displayOnMenu: false,
  },
];

export default routes;
