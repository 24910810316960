const OndasBackground = ({ ...props }) => {
  return (
    <svg viewBox="0 0 2869 2575" fill="none" className="ondas" {...props}>
      <path
        d="M2320.29 2151.56L2312.82 2144.2C2402.86 2052.17 2443.57 2050.18 2564.43 2044.18L2583.89 2043.23C2597.67 2042.5 2610.72 2041.97 2623.02 2041.45C2757.88 2035.56 2808.37 2033.35 2836.14 1900.93C2868.22 1748.74 2862.65 1475.81 2837.51 1326.15C2818.68 1213.19 2741.78 1150.93 2660.37 1084.98C2601.46 1037.34 2540.56 988.013 2503.84 917.756C2423.16 763.359 2520.78 513.779 2606.93 293.437C2646.06 193.521 2682.98 99.0738 2697.39 26.5031L2707.91 28.5014C2693.29 102.124 2656.27 196.781 2616.92 297.329C2531.61 515.462 2434.84 762.728 2513.42 912.918C2549.08 981.177 2609.14 1029.87 2667.2 1076.89C2746.52 1141.04 2828.57 1207.51 2848.13 1324.36C2873.38 1474.97 2878.95 1749.69 2846.66 1902.83C2817.1 2043.23 2760.51 2045.76 2623.66 2051.75L2584.63 2053.54L2565.17 2054.49C2447.15 2060.48 2407.49 2062.48 2320.29 2151.56Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M2278.95 2160.82L2271.58 2153.35C2363.1 2062.27 2401.81 2058.7 2529.93 2048.18L2559.49 2045.86C2696.24 2035.35 2741.89 2031.67 2769.87 1902.3C2805.21 1742.12 2813.73 1471.08 2788.17 1323C2768.4 1207.3 2689.19 1144.93 2605.35 1078.57C2543.82 1029.98 2480.28 979.6 2442.52 906.818C2362.78 753.263 2461.87 511.991 2549.18 299.117C2591.26 196.887 2630.7 100.442 2645.33 25.6621L2655.84 27.6604C2641.12 103.702 2601.25 200.673 2559.17 303.324C2472.6 513.674 2374.57 752.947 2451.99 902.191C2488.8 972.973 2551.5 1022.62 2612.08 1070.47C2693.82 1135.15 2778.29 1202.04 2798.8 1321.31C2824.46 1470.87 2815.84 1743.49 2780.39 1904.61C2750.2 2041.34 2698.34 2045.65 2559.49 2056.38L2531.09 2058.7C2402.34 2069.63 2367.73 2072.47 2278.95 2160.82Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M2254.44 2183.12L2246.65 2175.97C2328.07 2086.46 2361.84 2078.47 2478.07 2061.96H2481.54C2630.07 2040.92 2661.1 2036.4 2691.29 1907.35C2732.63 1731.6 2771.55 1471.61 2745.68 1317.42C2725.58 1197.42 2640.49 1132.31 2549.92 1063.32C2483.86 1012.94 2415.59 960.879 2375.72 885.469C2295.15 732.755 2399.49 501.37 2491.43 297.225C2537.08 196.046 2580.11 100.547 2595.89 25.137L2606.4 27.2405C2590.42 103.808 2547.08 199.833 2501.21 301.537C2410.12 503.473 2306.82 732.755 2385.5 880.526C2424.21 954.148 2491.54 1005.26 2556.65 1055.01C2644.69 1122.11 2735.47 1191.74 2756.51 1315.74C2782.7 1471.4 2743.47 1733.07 2702.02 1909.77C2670.46 2045.86 2634.28 2050.91 2483.44 2072.37H2479.97C2363.94 2088.78 2333.65 2095.93 2254.44 2183.12Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M2244.02 2214.78L2234.24 2210.78C2270.74 2120.64 2345.11 2097.08 2416.85 2074.05C2490.48 2050.81 2559.59 2028.83 2594.62 1941.43C2616.19 1887.68 2625.34 1820.58 2634.07 1755.58C2639.49 1709.09 2647.57 1662.95 2658.26 1617.38C2662.37 1601.61 2666.89 1585.83 2671.41 1569.21C2695.5 1483.18 2720.33 1394.2 2703.5 1309.01C2679.09 1185.53 2586.63 1116.33 2488.8 1043.02C2406.86 981.704 2322.18 918.283 2283.37 820.47C2222.99 667.967 2324.18 481.281 2422.01 300.696C2476.6 199.938 2528.25 104.649 2546.97 22.5076L2557.49 24.8214C2538.35 108.961 2486.49 204.25 2431.26 305.744C2334.59 484.541 2234.66 668.703 2293.15 816.579C2330.81 911.237 2414.33 974.342 2495.11 1034.61C2589.78 1105.81 2688.66 1179.54 2713.8 1306.9C2731.16 1394.62 2705.91 1484.86 2681.62 1572.05C2677.09 1588.25 2672.57 1604.23 2668.47 1620.01C2657.86 1665.09 2649.89 1710.74 2644.59 1756.74C2635.65 1822.26 2626.5 1890.1 2604.41 1945C2567.48 2037.03 2492.59 2060.69 2420.11 2083.83C2347.64 2106.97 2278.63 2129.16 2244.02 2214.78Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M2242.97 2255.37L2232.45 2253.06C2250.34 2175.55 2293.15 2152.83 2342.69 2126.85C2362.09 2116.94 2380.91 2105.95 2399.07 2093.93C2460.72 2051.86 2465.87 2036.4 2485.01 1979.6C2487.96 1970.98 2491.22 1961.3 2495.01 1950.47C2512.78 1900.41 2526.56 1846.56 2538.98 1794.5C2552.96 1733.67 2570.52 1673.72 2591.57 1614.96C2597.57 1599.29 2603.99 1583.41 2610.51 1567C2645.85 1478.97 2682.46 1388.2 2665.94 1297.33C2641.85 1165.23 2538.87 1091.82 2429.79 1013.99C2339.43 949.309 2246.13 883.154 2204.05 780.188C2142.41 629.472 2253.39 454.145 2360.57 284.393C2420.74 188.999 2477.55 99.39 2499.22 20.7191L2509.73 23.4536C2487.64 104.123 2430.53 194.679 2369.94 290.493C2264.11 457.932 2154.61 631.05 2214.25 776.717C2255.07 876.528 2347.11 942.157 2436.21 1005.68C2542.13 1081.2 2651.74 1159.34 2676.67 1295.65C2693.71 1389.26 2656.58 1481.71 2620.61 1571.1C2614.08 1587.3 2607.67 1603.29 2601.78 1618.96C2580.91 1677.46 2563.6 1737.17 2549.92 1797.76C2536.98 1850.34 2523.51 1904.19 2505.74 1954.68C2501.95 1965.2 2498.69 1975.08 2495.74 1983.71C2476.7 2040.18 2470.29 2059.12 2405.7 2103.29C2387.27 2115.59 2368.12 2126.79 2348.37 2136.84C2298.72 2161.87 2259.8 2182.59 2242.97 2255.37Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M2248.76 2301.65L2238.24 2300.81C2243.5 2232.34 2281.37 2169.45 2317.87 2108.65C2346.48 2061.11 2376.14 2011.89 2390.34 1959.94C2408.65 1893.68 2448.94 1811.64 2491.64 1724.87C2565.28 1574.15 2649.43 1403.35 2629.76 1283.14C2606.41 1141.67 2485.96 1059.53 2369.52 980.021C2270.01 912.078 2167.03 841.926 2121.58 734.437C2058.47 584.247 2180.07 421.646 2298.09 264.515C2364.36 176.273 2426.95 92.9745 2451.99 17.1433L2461.98 20.5089C2436.31 97.9177 2373.3 181.847 2306.51 270.825C2190.27 425.643 2070.04 585.719 2131.26 730.335C2175.55 835.51 2277.16 904.295 2375.41 971.396C2493.75 1052.07 2616.08 1135.57 2640.17 1281.35C2660.58 1404.93 2575.79 1577.31 2501 1729.39C2458.93 1815.74 2418.54 1897.67 2400.55 1962.67C2385.82 2015.99 2355.95 2065.85 2326.91 2114.02C2289.36 2176.81 2253.81 2235.71 2248.76 2301.65Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M2247.71 2351.4C2244.97 2234.02 2254.12 2055.44 2280.95 1940.69C2302.83 1846.66 2367.94 1754.11 2436.94 1656.09C2525.93 1529.88 2617.87 1398.83 2605.88 1266.94C2592.52 1120.32 2452.3 1026.19 2316.71 935.321C2219.3 869.902 2118.53 802.38 2068.78 716.346C1971.16 548.066 2104.65 397.351 2233.82 251.894C2303.04 174.064 2374.46 94.131 2405.18 13.8824L2414.96 17.5636C2383.4 99.1794 2311.45 180.375 2241.71 258.835C2115.48 401.452 1983.99 549.013 2077.82 711.088C2126.42 795.228 2221.72 858.333 2322.5 926.591C2460.19 1018.83 2602.51 1114.33 2616.4 1265.99C2628.7 1401.35 2535.61 1533.66 2445.46 1661.76C2377.19 1758.84 2312.61 1851.08 2291.15 1942.58C2264.54 2056.59 2255.49 2234.02 2258.22 2350.77L2247.71 2351.4Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M2268.22 2401.88H2257.7C2258.33 2332.78 2234.24 2267.36 2210.99 2204.15C2181.65 2124.11 2151.25 2041.45 2170.08 1950.26C2192.59 1841.82 2282 1738.86 2368.46 1639.47C2474.5 1517.46 2584.1 1391.25 2574.22 1252.95C2563.17 1099.71 2407.17 998.005 2256.23 899.667C2144.72 826.885 2039.22 758.206 1987.15 669.859C1883.54 494.322 2027.02 362.222 2165.97 234.434C2242.66 163.757 2321.97 90.7654 2358.79 11.2531L2368.36 15.6704C2330.28 96.9707 2250.23 170.698 2172.49 242.112C2030.59 372.74 1896.58 496.215 1995.88 664.39C2046.79 750.633 2151.14 818.682 2261.7 890.727C2414.75 990.538 2572.95 1093.61 2584.31 1252.11C2594.83 1394.83 2483.54 1522.62 2376.04 1646.2C2290.52 1744.64 2201.95 1846.03 2180.07 1952.26C2161.87 2040.5 2190.59 2118.12 2220.57 2200.37C2244.44 2264.52 2268.85 2330.78 2268.22 2401.88Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M2265.27 2451.74C2248.13 2393.57 2210.47 2334.78 2170.6 2272.94C2105.7 2171.65 2038.59 2066.79 2061.52 1959.41C2086.45 1842.77 2193.85 1736.54 2297.78 1633.37C2421.48 1510.84 2549.5 1384.21 2541.4 1238.75C2536.56 1150.51 2428.11 1061.74 2356.37 1003.05L2331.12 982.019C2292.2 949.204 2244.45 916.285 2194.37 881.262C2087.92 807.639 1967.16 724.445 1907 624.108C1797.39 441.735 1950.23 326.568 2097.92 215.293C2182.07 152.188 2269.06 86.2434 2311.87 7.67761L2321.13 12.726C2277.26 93.2901 2189.33 159.971 2104.23 223.707C1953.59 337.191 1811.38 444.575 1915.94 618.745C1975.05 717.083 2094.76 799.751 2199.95 872.637C2250.76 907.766 2298.72 940.896 2337.96 973.921L2363.1 994.956C2436.73 1054.8 2546.87 1145.25 2552.44 1238.23C2560.75 1388.42 2431.05 1516.73 2305.67 1640.84C2202.9 1742.43 2096.65 1847.61 2072.35 1961.2C2050.26 2064.38 2116.22 2167.34 2179.96 2266.84C2220.15 2329.94 2258.23 2389.05 2275.79 2448.58L2265.27 2451.74Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M2267.17 2495.8C2240.76 2430.59 2183.01 2368.54 2120.95 2302.91C2032.38 2208.25 1940.76 2110.65 1948.54 2000.11C1959.06 1853.92 2093.71 1738.44 2223.93 1626.74C2363.31 1507.37 2507.32 1383.89 2490.06 1227.08C2470.92 1052.59 2302.72 952.99 2140.1 856.65C1997.25 772.51 1849.56 684.689 1799.91 546.068C1751.1 409.341 1782.03 363.379 1906.57 283.972C1943.29 260.623 1978.21 241.271 2011.76 222.55C2109.91 168.174 2194.69 121.266 2264.22 5.99438L2273.16 11.3583C2202.68 129.049 2116.74 176.693 2017.23 232.226C1983.57 250.842 1948.75 270.089 1912.04 293.333C1790.97 370.426 1762.78 412.601 1809.59 542.913C1857.98 677.958 2004.09 764.412 2145.25 847.921C2310.08 945.523 2480.6 1046.49 2500.27 1226.55C2518.15 1389.47 2371.94 1514.73 2230.66 1635.89C2102.02 1745.59 1968.95 1859.07 1958.96 2000.85C1951.49 2106.87 2041.53 2202.89 2128.53 2295.34C2190.8 2361.7 2249.6 2424.49 2276.74 2491.49L2267.17 2495.8Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M2259.7 2532.61C2200.69 2411.24 2142.94 2362.65 2063.1 2295.34C2037.75 2274.3 2008.92 2249.8 1977.47 2220.88C1921.41 2169.44 1847.46 2091.3 1852.09 2007.47C1860.61 1854.45 2010.82 1736.86 2156.08 1623.27C2309.24 1503.69 2468.29 1378.95 2451.14 1216.03C2432 1035.34 2254.33 932.06 2082.45 832.144C1931.92 744.744 1776.35 654.293 1724.8 511.465C1694.83 487.065 1678.31 460.35 1675.79 432.164C1673.05 401.663 1686.3 369.058 1716.07 334.982L1717.23 334.035L1837.36 256.837C1877.33 235.802 1914.78 218.343 1951.07 202.041C2053.1 155.869 2141.36 116.113 2216.04 3.25977L2224.88 9.04438C2148.41 124.737 2058.99 164.809 1955.38 211.611C1919.3 227.914 1881.75 244.742 1842.72 265.882L1723.54 342.449C1696.3 374.002 1683.78 403.871 1686.2 431.217C1688.51 456.774 1704.19 481.385 1732.59 504.208L1733.75 505.05V506.522C1783.92 646.51 1938.34 736.33 2087.71 823.099C2261.91 924.277 2441.89 1028.93 2461.56 1214.88C2479.33 1383.68 2318.29 1509.37 2162.61 1631.47C2019.23 1743.7 1870.91 1859.81 1862.6 2008.11C1858.19 2087.41 1930.14 2163.13 1984.62 2213.09C2016.18 2241.91 2044.58 2265.68 2069.83 2287.35C2150.72 2355.39 2209.21 2404.62 2269.16 2527.99L2259.7 2532.61Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M2240.03 2559.22C2172.81 2432.07 2105.8 2381.69 2013.24 2312.17C1982.73 2289.24 1948.23 2263.37 1910.89 2232.23C1862.71 2192.16 1762.36 2108.55 1767.2 2012.94C1775.19 1855.18 1936.66 1736.12 2093.29 1621.17C2257.38 1500.43 2427.16 1375.48 2409.7 1208.35C2390.24 1023.25 2205.42 917.231 2026.6 814.685C1869.86 724.865 1707.76 631.891 1654.54 485.172C1623.72 460.771 1606.78 434.056 1604.15 405.87C1601.42 375.474 1615.41 342.765 1645.6 309.003L1646.86 307.846L1772.03 236.643C1814.11 216.765 1854.08 201.83 1892.37 187.421C1997.56 147.98 2087.92 113.798 2166.39 1.68188L2175.02 7.67686C2094.65 122.633 2002.61 157.236 1896.16 196.992C1857.98 211.296 1818.53 226.125 1776.87 245.688L1652.85 316.26C1625.08 347.813 1612.25 377.367 1614.67 404.607C1616.98 430.165 1632.97 454.776 1662.22 477.599L1663.37 478.44L1663.9 479.913C1715.34 623.792 1876.28 716.031 2032.07 805.219C2213.1 909.132 2400.23 1016.52 2420.32 1206.99C2438.52 1380.11 2266.22 1506.84 2099.7 1629.37C1945.39 1742.85 1785.92 1860.76 1778.24 2013.15C1773.72 2103.5 1871.23 2184.8 1918.14 2223.5C1955.28 2254.32 1989.67 2280.19 2020.07 2303.02C2110.22 2370.75 2181.44 2424.28 2249.91 2553.65L2240.03 2559.22Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M2204.89 2573.32C2135.57 2441.74 2059.41 2388.63 1962.96 2320.9C1928.77 2297.02 1889.95 2269.89 1848.4 2236.76C1789.71 2190.38 1691.56 2112.86 1696.72 2015.26C1705.34 1854.34 1873.33 1734.97 2035.75 1619.49C2206.05 1498.53 2382.14 1373.38 2363.94 1203.73C2343.85 1016.1 2154.82 908.607 1971.9 804.694C1811.27 713.402 1645.28 619.06 1591.11 469.817C1560.29 445.311 1543.25 418.597 1540.72 390.305C1537.88 359.804 1551.77 327.2 1581.96 293.018L1583.43 291.861L1711.66 224.233C1756.15 205.196 1797.39 191.839 1837.36 178.798C1943.6 144.195 2035.43 114.325 2115.17 0L2123.79 5.99497C2042.06 123.16 1948.65 153.24 1840.62 188.684C1800.86 201.621 1759.83 214.978 1716.07 233.594L1589.11 300.59C1561.44 332.143 1548.61 361.907 1551.14 389.253C1553.55 414.916 1569.54 439.526 1598.79 462.349L1600.05 463.296L1600.58 464.768C1653.17 611.067 1817.69 704.778 1977.16 795.439C2162.29 900.614 2353.74 1009.58 2374.35 1202.47C2393.29 1378.32 2214.57 1505.27 2041.85 1628C1881.43 1742.01 1715.76 1859.39 1707.24 2015.78C1702.4 2107.81 1797.81 2183.33 1854.5 2228.34C1895.84 2261.05 1932.77 2286.82 1968.53 2311.85C2066.15 2380.01 2143.25 2433.75 2213.83 2567.64L2204.89 2573.32Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M2151.46 2575C2082.14 2444.79 2005.66 2390.21 1908.89 2321.11C1873.44 2295.76 1836.83 2269.68 1796.02 2236.97C1733.85 2187.11 1640.02 2111.7 1644.65 2016.1C1652.54 1854.97 1820.74 1735.28 1983.36 1619.48C2153.67 1498.32 2329.75 1373.06 2311.98 1203.31C2292.2 1015.15 2102.44 908.817 1918.78 805.115C1758.05 714.874 1591.42 620.217 1537.15 471.394C1506.54 446.888 1489.7 420.069 1487.07 391.672C1484.23 361.066 1497.59 328.567 1527.57 294.174L1529.05 293.017L1657.9 225.285C1702.93 206.248 1744.69 192.891 1785.08 179.954C1891.85 145.877 1983.99 116.849 2063.2 1.15662L2071.93 7.15159C1990.62 125.263 1896.9 155.343 1787.92 190.051C1747.74 202.882 1706.29 216.134 1661.69 234.856L1534.09 301.852C1506.75 333.405 1494.23 363.274 1496.75 390.725C1499.17 416.493 1515.06 441.209 1544.09 464.347L1545.35 465.294V466.661C1597.95 613.065 1763.3 706.145 1923.19 796.07C2109.17 900.614 2301.35 1008.73 2321.65 1202.36C2340.06 1378.11 2161.45 1505.26 1988.73 1628.21C1828.1 1742.43 1662.01 1860.65 1654.43 2016.73C1650.01 2106.97 1741.42 2180.28 1801.7 2228.87C1842.2 2261.47 1878.7 2287.56 1914.04 2313.01C2011.97 2382.85 2089.29 2438.06 2159.77 2570.48L2151.46 2575Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M2083.4 2575C2016.81 2448.79 1944.97 2390.84 1853.98 2317.22C1822.42 2291.87 1790.86 2265.78 1754.89 2234.23C1699.87 2185.54 1607.62 2103.82 1611.73 2013.36C1618.77 1854.34 1781.82 1733.81 1939.5 1617.38C2104.96 1495.17 2276.11 1368.75 2258.54 1200.78C2238.97 1013.89 2050.16 910.92 1867.55 811.635C1707.34 724.234 1541.46 633.889 1487.29 486.434C1456.67 461.928 1439.74 435.213 1437.11 406.816C1434.27 376.315 1447.63 343.711 1477.61 309.424L1478.98 308.267L1608.04 237.695C1652.43 217.817 1693.98 203.408 1734.27 189.419C1841.99 152.082 1934.98 119.794 2014.08 4.62695L2022.7 10.6219C1941.71 128.523 1847.14 161.338 1737.74 199.937C1697.77 213.82 1656.43 228.124 1612.78 247.581L1484.97 317.628C1457.73 349.18 1445.1 379.05 1447.73 406.395C1450.36 433.741 1466.04 456.774 1495.07 480.018L1496.23 480.965L1496.75 482.437C1549.35 626.948 1713.65 716.557 1872.7 803.326C2057.63 904.189 2248.97 1008.63 2269.06 1200.99C2287.26 1374.85 2113.69 1503.06 1945.81 1627.06C1790.13 1742.12 1629.08 1861.07 1622.35 2015.05C1618.56 2100.98 1708.29 2180.59 1761.94 2228.13C1797.39 2259.68 1829.57 2285.45 1860.71 2310.69C1952.54 2385.16 2025.23 2443.85 2092.87 2571.74L2083.4 2575Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M2002.4 2574.79C1968.74 2504.11 1896.16 2433.65 1819.37 2358.97C1704.71 2247.59 1586.16 2132.32 1592.69 2007.27C1600.79 1852.03 1754.68 1730.87 1903.52 1613.6C2061.31 1489.59 2223.72 1361.18 2204.89 1196.26C2183.86 1012.31 1997.25 915.444 1816.74 821.733C1658.01 739.38 1493.91 654.189 1439.11 509.468C1408.6 485.383 1391.77 458.773 1389.04 430.587C1386.2 400.086 1399.56 367.481 1429.75 332.879L1431.01 331.722L1560.39 256.522C1604.26 235.487 1645.7 219.29 1685.67 203.934C1794.55 161.864 1888.59 125.684 1967.37 8.83459L1976 14.7244C1895.42 134.308 1800.02 171.12 1689.46 213.821C1649.59 229.176 1608.46 245.373 1565.34 266.408L1437.11 341.083C1409.55 372.635 1396.93 402.82 1399.56 430.271C1401.98 455.829 1417.86 480.229 1446.79 502.737L1447.94 503.683V505.05C1500.54 646.826 1663.58 731.282 1821.05 813.003C2004.09 907.661 2193.32 1006.21 2214.89 1195.73C2234.24 1366.64 2069.2 1496.75 1909.84 1622.54C1762.57 1738.23 1610.88 1858.02 1602.99 2008.42C1596.68 2128.74 1713.55 2242.23 1826.52 2352.03C1904.05 2427.33 1977.26 2498.54 2011.66 2570.9L2002.4 2574.79Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M1911.41 2574.37C1884.8 2505.27 1827.26 2433.01 1765.41 2356.55C1671.68 2239.91 1574.8 2119.38 1584.9 1999.59C1597.42 1851.18 1737.85 1729.39 1873.65 1611.6C2027.44 1478.23 2172.71 1352.34 2151.56 1190.9C2128 1011.05 1944.34 922.174 1766.67 836.141C1609.83 760.204 1447.73 681.638 1392.3 540.494C1362 516.829 1345.17 490.641 1342.44 462.664C1339.39 432.058 1352.96 398.928 1383.57 364.22L1384.73 363.274L1514.43 281.868C1557.97 259.045 1599.1 241.165 1638.97 223.917C1749.21 176.062 1844.3 134.833 1922.98 15.0391L1931.82 20.8237C1851.67 143.247 1755.1 185.107 1643.39 233.488C1603.73 250.736 1562.71 268.511 1519.9 291.018L1391.04 372.003C1363.06 404.292 1350.22 434.582 1352.96 462.033C1355.38 487.275 1371.26 511.255 1399.87 533.447L1401.14 534.288L1401.66 535.655C1454.99 673.856 1615.83 751.685 1771.19 826.99C1951.6 914.706 2137.68 1004.42 2161.98 1189.74C2183.86 1356.55 2029.65 1490.22 1880.59 1619.59C1746.37 1735.91 1607.1 1856.34 1595.42 2000.43C1585.64 2116.12 1681.15 2234.97 1773.61 2350.03C1835.57 2427.12 1894.06 2500.01 1920.88 2570.9L1911.41 2574.37Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M1813.16 2573.84C1794.55 2504.11 1753.31 2430.28 1709.66 2352.13C1642.34 2231.6 1572.91 2106.97 1585.43 1990.44C1600.58 1850.87 1725.96 1728.45 1847.14 1610.02C1988.83 1472.13 2122.63 1341.09 2098.86 1185.32C2071.72 1010.84 1891.74 931.009 1717.55 853.916C1562.39 785.447 1402.82 714.874 1346.65 578.147C1316.46 555.114 1299.73 529.451 1296.89 501.79C1293.73 471.079 1307.41 437.633 1338.97 402.294L1340.02 401.453L1470.14 313.106C1513.16 288.179 1554.29 268.196 1593.84 248.949C1705.55 194.468 1802.01 147.56 1880.07 23.6642L1888.9 29.3436C1809.27 155.554 1711.55 203.303 1598.47 258.415C1559.02 277.662 1518.11 297.54 1475.71 321.52L1346.44 409.341C1317.82 442.156 1304.36 472.446 1307.62 500.317C1310.14 525.244 1325.92 548.803 1354.43 570.259L1355.59 571.1L1356.11 572.467C1410.18 706.46 1568.49 776.612 1721.65 844.345C1898.37 922.7 2081.08 1003.58 2109.07 1183.74C2134.1 1344.98 1992.09 1483.6 1854.82 1617.7C1734.9 1734.76 1610.99 1855.81 1596.16 1991.7C1583.85 2104.97 1652.64 2228.03 1719.12 2347.09C1763.09 2425.86 1804.75 2500.33 1823.68 2571.21L1813.16 2573.84Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M1710.5 2573.21C1701.03 2503.27 1678 2429.12 1653.7 2350.56C1614.99 2225.61 1574.91 2096.45 1591.11 1980.44C1608.99 1852.76 1717.34 1730.55 1821.69 1612.33C1945.28 1472.66 2072.99 1328.36 2045.32 1179.01C2013.76 1010.73 1837.89 940.684 1667.69 873.057C1515.16 812.581 1357.59 750.002 1300.99 616.745C1239.67 472.445 1305.2 422.171 1426.38 345.604C1468.46 318.89 1509.48 296.698 1548.93 275.242C1662.22 213.609 1759.94 160.391 1837.57 31.8669L1846.62 37.336C1767.51 167.963 1668.53 222.023 1553.98 284.287C1514.64 305.322 1474.03 327.935 1431.96 354.439C1302.57 436.16 1255.34 482.437 1310.67 612.538C1366 742.64 1521.05 803.536 1671.47 863.17C1844.41 931.85 2023.23 1002.84 2055.63 1177.01C2084.24 1331.3 1954.75 1477.6 1829.57 1618.75C1726.07 1735.91 1619.19 1856.97 1601.52 1981.5C1585.64 2095.19 1625.3 2223.4 1663.69 2347.08C1688.2 2425.97 1711.34 2500.54 1720.91 2571.42L1710.5 2573.21Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M1616.46 2572.48H1605.94C1605.94 2505.69 1601.21 2436.91 1596.16 2363.91C1587.01 2231.18 1577.54 2093.82 1599.1 1970.03C1618.67 1857.08 1708.5 1736.65 1795.39 1620.22C1907.52 1469.82 2023.44 1314.37 1992.2 1172.39C1956.54 1010.21 1784.55 950.467 1618.35 892.62C1468.67 840.664 1313.93 786.814 1256.18 658.396C1193.59 519.354 1263.55 462.034 1382.94 379.892C1425.01 351.179 1465.41 326.674 1504.64 303.009C1619.4 233.804 1718.6 174.17 1795.91 40.7026L1804.96 45.9614C1726.28 181.742 1626.14 242.113 1510.43 311.949C1471.3 335.613 1430.8 360.014 1389.25 388.622C1270.91 469.922 1206.85 522.404 1266.07 654.083C1321.93 778.4 1474.56 831.409 1622.03 882.734C1791.07 941.527 1965.9 1002.21 2002.82 1170.18C2035.12 1316.79 1917.62 1474.24 1804.12 1626.53C1717.97 1742.22 1628.87 1861.39 1609.73 1971.82C1588.69 2094.35 1597.84 2231.08 1606.99 2363.28C1611.73 2436.27 1616.46 2505.37 1616.46 2572.48Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M1512.64 2573.21L1502.12 2571.84C1522.21 2416.82 1551.24 2259.47 1579.22 2107.29C1588.27 2058.06 1597.31 2008.95 1606.04 1960.04C1624.14 1858.44 1694.72 1746.22 1769.41 1627.37C1869.97 1467.4 1974 1302.07 1939.08 1166.08C1899.11 1010.21 1731.12 960.248 1568.81 911.973C1421.54 868.325 1270.07 823.205 1211.16 699.834C1143.84 559.11 1230.09 495.059 1339.28 413.969C1380.73 383.152 1421.12 356.543 1460.25 330.775C1576.49 254.103 1676.84 187.842 1753.94 49.6425L1763.09 54.6909C1684.94 195.1 1583.43 261.991 1466.14 339.4C1427.12 365.168 1386.83 391.987 1345.7 422.278C1225.26 511.676 1160.14 568.471 1220.73 695.102C1277.64 814.265 1427.22 858.754 1571.86 901.771C1737.11 950.887 1908.47 1001.69 1949.39 1163.34C1985.36 1303.64 1880.17 1471.08 1778.45 1632.84C1704.29 1750.85 1634.24 1862.23 1616.46 1961.83C1607.73 2010.74 1598.68 2059.96 1589.64 2109.07C1561.65 2260.95 1532.73 2418.71 1512.64 2573.21Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M1412.18 2573.74L1401.66 2571.21C1426.17 2471.19 1464.04 2375.59 1500.64 2283.03C1542.72 2176.6 1586.27 2066.58 1608.99 1950.37C1637.81 1803.12 1695.25 1682.7 1756.26 1554.8C1765.3 1535.77 1774.46 1516.52 1783.71 1496.85C1791.81 1479.6 1801.28 1461.09 1811.27 1441.42C1855.87 1354.44 1911.31 1246.11 1886.06 1159.87C1842.2 1009.89 1678 969.083 1519.16 929.642C1375.05 893.882 1226.1 856.966 1165.82 738.329C1097.03 603.074 1184.55 533.553 1295.21 445.627C1336.23 413.022 1376.52 384.415 1415.44 356.648C1533.04 272.508 1634.55 200.463 1711.55 57.741L1720.81 62.6842C1642.65 207.615 1535.25 284.183 1421.54 365.273C1382.73 392.934 1342.54 421.436 1301.73 453.935C1194.75 538.812 1110.28 605.913 1175.5 733.596C1233.57 847.921 1380.2 884.312 1522 919.44C1683.68 959.617 1850.93 1001.06 1896.48 1156.93C1922.88 1247.17 1866.39 1357.49 1820.95 1446.26C1810.96 1465.72 1801.49 1484.23 1793.49 1501.37C1784.24 1521.04 1775.09 1540.29 1766.04 1559.32C1702.93 1692.06 1648.54 1806.8 1620.04 1952.47C1597 2069.53 1553.24 2180.07 1510.95 2286.93C1474.24 2379.06 1436.16 2474.35 1412.18 2573.74Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M1317.61 2574.16L1307.72 2570.79C1341.49 2470.67 1395.45 2379.79 1447.63 2291.87C1512.32 2183.12 1579.12 2071 1605.41 1942.16C1639.92 1775.04 1689.04 1643.99 1749.63 1489.91C1756.68 1471.92 1765.09 1452.57 1774.03 1432.06C1812.01 1344.98 1859.34 1236.65 1832.62 1154.19C1785.6 1009.05 1624.56 976.444 1468.77 944.891C1327.29 916.178 1180.87 886.519 1119.43 772.299C1049.27 641.882 1139.74 565.841 1244.51 477.809L1249.76 473.497C1290.47 439.315 1330.55 408.814 1369.26 379.365C1488.02 289.125 1590.16 211.085 1667.48 64.6815L1676.84 69.5196C1598.89 218.132 1490.44 300.905 1375.57 387.779C1336.97 417.123 1297 447.519 1256.6 481.49L1251.34 485.908C1146.15 574.465 1062.53 644.406 1128.69 767.251C1187.81 877.159 1331.71 906.397 1470.87 934.584C1629.61 966.663 1793.7 999.898 1842.62 1150.93C1870.6 1237.17 1822.42 1347.61 1783.71 1436.27C1774.77 1456.67 1766.46 1475.92 1759.41 1493.69C1699.03 1647.14 1650.12 1777.67 1615.72 1944.05C1588.9 2074.26 1521.58 2187.53 1456.46 2297.02C1404.6 2384.74 1351.06 2475.08 1317.61 2574.16Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M1231.78 2574.47L1222.1 2570.58C1263.12 2467.72 1330.87 2378.53 1396.3 2292.29C1478.45 2184.06 1563.44 2072.26 1592.05 1935.54C1623.16 1783.08 1663.13 1632.56 1711.76 1484.76C1718.07 1465.72 1725.75 1445.1 1733.96 1423.33C1766.36 1336.67 1806.75 1228.76 1779.4 1149.88C1730.27 1008 1571.75 981.598 1418.39 957.092C1278.9 934.375 1134.37 910.815 1072.1 799.961C1000.78 673.751 1094.08 591.82 1192.86 505.471L1203.38 496.321C1243.66 460.877 1283.64 428.903 1322.24 398.087C1436.9 306.374 1545.25 219.29 1622.14 70.6772L1631.5 75.5153C1553.56 226.652 1444.37 313.947 1328.76 406.291C1290.26 437.107 1250.4 469.396 1210.32 504.209L1199.8 513.359C1100.29 600.549 1014.35 675.854 1081.46 794.913C1141.42 901.35 1283.11 924.488 1420.17 946.785C1576.59 972.343 1738.27 998.742 1789.39 1146.62C1818 1229.18 1776.87 1338.98 1743.95 1427.22C1735.74 1448.89 1728.07 1469.29 1721.86 1488.33C1673.32 1635.75 1633.45 1785.88 1602.47 1937.95C1572.91 2076.68 1487.6 2189.53 1404.61 2298.6C1339.6 2384.32 1272.28 2472.98 1231.78 2574.47Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M1157.41 2574.58L1147.73 2570.48C1192.96 2463.09 1271.12 2374.33 1346.65 2288.5C1444.05 2177.75 1535.99 2073.1 1565.55 1930.91L1577.12 1874.95C1604.47 1741.7 1630.34 1615.91 1668.32 1481.07C1674.1 1460.56 1681.36 1438.37 1689.36 1415.02C1718.7 1325.2 1752.47 1223.4 1725.96 1145.99C1678.1 1004.95 1520.31 984.122 1367.26 963.928C1229.04 945.733 1085.98 926.907 1023.08 818.787C951.025 694.996 1042.96 612.118 1140.26 524.297L1154.46 511.465C1194.54 475.18 1234.41 442.155 1272.91 410.182C1388.62 314.788 1497.07 224.548 1574.07 74.1476L1583.43 78.9856C1505.38 231.069 1395.77 322.255 1279.64 418.385C1241.14 449.938 1201.38 483.173 1161.51 519.353L1147.31 532.185C1053.27 616.956 964.384 697.099 1032.13 813.528C1092.51 917.125 1232.93 935.636 1368.74 953.516C1524.63 974.551 1685.88 995.586 1735.74 1142.83C1762.99 1223.5 1729.01 1327.1 1699.14 1418.49C1691.46 1441.84 1684.2 1463.83 1678.1 1484.12C1640.34 1618.54 1614.99 1744.22 1587.11 1877.27L1575.54 1933.33C1545.56 2077.94 1448.26 2188.48 1354.64 2295.34C1279.53 2380.64 1202.01 2468.77 1157.41 2574.58Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M1096.92 2574.47L1087.25 2570.58C1132.79 2456.15 1217.05 2367.07 1298.68 2280.83C1399.35 2174.28 1494.54 2073.74 1523.05 1929.75C1528.24 1903.81 1533.29 1878.04 1538.2 1852.45C1563.02 1726.24 1586.48 1606.02 1618.04 1480.23C1623.61 1458.04 1631.08 1433.75 1639.07 1407.87C1666.32 1319 1697.24 1218.56 1671.79 1144.93C1622.77 1003.26 1466.25 985.279 1314.98 967.82C1177.71 951.939 1035.81 935.636 972.168 827.622C899.692 704.357 994.363 617.587 1086.3 533.552L1103.13 518.197C1143 481.386 1182.86 447.729 1221.36 415.23C1337.07 317.628 1445.84 225.915 1523.15 75.6202L1532.52 80.4583C1454.26 232.226 1344.44 324.99 1228.1 423.224C1189.7 455.723 1149.94 489.274 1110.28 525.875L1093.45 541.335C1000.67 626.211 913.156 706.355 981.109 821.732C1042.12 925.224 1180.97 941.316 1316.03 956.777C1470.66 974.656 1630.45 993.062 1681.57 1141.04C1708.18 1218.03 1676.84 1320.58 1649.07 1411.03C1641.18 1436.69 1633.81 1460.88 1628.03 1482.76C1596.47 1608.34 1573.12 1727.92 1548.3 1854.45C1543.32 1880.04 1538.27 1905.81 1533.15 1931.75C1504.01 2078.99 1403.34 2185.12 1306.04 2288.08C1225.78 2373.59 1141.63 2461.94 1096.92 2574.47Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M1048.12 2563.22L1038.33 2559.33C1083.99 2443.64 1169.29 2354.66 1251.76 2268.73C1352.64 2163.56 1447.94 2064.27 1476.87 1919.87C1486.65 1870.75 1496.12 1821.11 1505.17 1773.15C1523.37 1677.44 1542.09 1578.47 1565.02 1480.23C1570.28 1457.41 1577.64 1432.48 1585.32 1406.08C1610.57 1319.63 1639.07 1221.82 1614.25 1149.67C1566.28 1010.1 1413.02 988.645 1264.81 967.821C1130.58 948.994 991.313 929.537 928.83 822.995C856.669 700.676 947.028 617.483 1042.75 529.346L1057.48 515.042C1096.08 479.283 1134.58 446.468 1171.82 414.705C1285.53 317.629 1392.72 226.021 1470.45 77.5139L1479.71 82.4571C1401.66 233.068 1293.42 325.727 1178.97 423.119C1141.84 454.672 1103.45 487.591 1064.95 523.246L1049.9 537.129C957.336 622.215 870.029 702.569 937.981 817.631C998.255 919.756 1134.58 938.897 1266.28 957.408C1417.65 978.443 1574.07 1000.64 1623.93 1146.2C1649.8 1221.5 1620.77 1321.1 1595.1 1409.13C1587.43 1435.53 1580.17 1460.36 1574.91 1482.76C1552.08 1580.78 1532.83 1679.54 1515.16 1775.14C1506.11 1823.21 1496.75 1872.85 1486.86 1922.39C1457.31 2069.63 1356.53 2174.81 1259.02 2276.41C1177.6 2361.18 1093.03 2449.32 1048.12 2563.22Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M1005.93 2532.19L996.256 2528.3C1042.22 2412.61 1128.9 2325.31 1212.63 2240.75C1315.09 2137.37 1411.86 2039.66 1440.26 1895.25C1449.42 1848.24 1465.51 1787.34 1482.34 1722.87C1533.25 1529.98 1596.58 1289.87 1553.03 1159.45C1507.38 1022.72 1360.64 995.27 1218.63 968.766C1090.19 944.786 957.336 919.965 897.062 814.685C826.795 691.84 915.891 608.962 1010.14 521.141L1021.29 510.624C1058.21 476.126 1094.92 444.469 1130.16 413.968C1239.88 319.31 1343.49 229.912 1418.49 84.4545L1427.85 89.2925C1351.9 236.538 1247.56 326.567 1137.11 421.961C1101.76 452.357 1065.26 483.909 1028.55 518.301L1017.29 528.819C922.623 617.271 840.049 693.733 906.214 809.426C964.068 910.394 1094.5 934.795 1220.63 958.354C1365.47 985.384 1515.16 1013.36 1563.02 1155.98C1607.52 1289.44 1543.77 1531.24 1492.54 1725.5C1475.5 1789.76 1459.51 1850.45 1450.47 1897.15C1421.54 2044.39 1319.09 2148.09 1220 2248.12C1137.11 2332.05 1051.17 2418.71 1005.93 2532.19Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M969.223 2485.07L959.546 2480.97C1007.83 2367.8 1094.4 2281.14 1178.03 2197C1287.74 2087.3 1391.35 1983.6 1414.07 1826.05C1421.02 1778.3 1435.11 1715.93 1449.84 1649.99C1486.55 1486.44 1532.31 1282.92 1500.43 1166.18C1464.88 1036.08 1318.77 995.481 1177.5 955.83C1071.47 926.276 961.86 895.775 901.06 827.201C799.025 712.139 865.821 628.63 971.222 523.035C1014.35 479.913 1057.48 441.84 1099.24 404.923C1200.43 315.735 1296.05 231.384 1366.84 94.2362L1376.2 99.0742C1304.46 238.01 1207.9 322.887 1106.18 412.811C1064.63 449.623 1022.03 487.591 978.585 530.502C874.131 635.677 812.805 711.824 908.949 820.259C967.645 886.52 1075.78 916.6 1180.34 945.733C1324.45 985.805 1473.51 1027.24 1510.53 1163.13C1543.14 1282.4 1497.07 1487.28 1460.04 1651.98C1445.31 1717.72 1431.32 1779.88 1424.49 1827.31C1401.24 1988.23 1291.63 2098.03 1185.49 2204.15C1102.5 2287.87 1016.66 2373.8 969.223 2485.07Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M936.614 2425.65L926.937 2421.44C975.219 2312.48 1063.68 2228.87 1149.52 2147.99C1263.97 2039.97 1372 1937.64 1390.41 1781.88C1394.51 1746.22 1400.92 1702.89 1408.29 1657.03C1432.48 1499.27 1462.78 1302.07 1432.59 1184.48C1399.77 1056.27 1266.91 1007.16 1138.05 959.617C1040.75 923.542 940.191 885.994 883.388 814.896C788.717 695.943 854.04 612.224 949.027 515.147C989.315 473.919 1029.39 438.054 1068.21 403.241C1161.93 319.101 1250.4 239.694 1314.88 107.91L1324.35 112.537C1259.23 246.004 1170.03 325.727 1075.57 410.919C1036.86 445.627 996.994 481.491 956.916 522.404C865.611 615.695 802.812 696.048 892.014 808.27C946.923 877.475 1046.12 914.076 1142.05 949.625C1273.12 998.111 1408.6 1048.17 1442.79 1181.75C1473.4 1301.44 1442.79 1499.38 1418.6 1658.5C1411.65 1704.26 1405.03 1747.59 1400.82 1783.03C1381.99 1942.9 1267.86 2050.6 1156.78 2155.77C1071.89 2235.81 983.845 2318.69 936.614 2425.65Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M906.739 2357.71L897.377 2353.18C948.815 2247.06 1037.81 2167.66 1123.96 2090.88C1232.41 1994.22 1334.34 1902.83 1365.89 1763.78C1393.35 1641.25 1391.88 1332.57 1354.11 1207.83C1316.35 1083.09 1202.43 1027.56 1092.08 973.814C993.626 925.749 892.223 876.107 842.258 777.978C792.292 679.85 854.144 591.398 912.735 524.086C956.966 474.247 1004.37 427.312 1054.64 383.572C1134.79 311.317 1204.01 248.843 1263.76 122.528L1273.22 126.945C1212.63 255.259 1142.68 318.364 1061.68 391.46C1011.73 434.847 964.645 481.432 920.73 530.922C864.348 595.815 805.02 682.059 851.725 772.825C899.797 867.482 999.938 916.704 1096.82 964.033C1209.16 1018.83 1325.4 1075.52 1364.32 1204.46C1402.4 1330.67 1403.87 1642.1 1376.31 1765.78C1344.75 1908.19 1240.82 2000.53 1131.01 2098.45C1045.59 2174.91 957.336 2253.58 906.739 2357.71Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M878.653 2285.03L869.291 2280.09C938.717 2150.3 1001.94 2087.41 1117.33 1995.49L1150.78 1969.09C1261.86 1881.79 1335.18 1824.15 1350.12 1681.12C1362.21 1565.42 1323.08 1345.92 1291.52 1226.87C1258.18 1101.6 1161.83 1038.71 1060.11 972.027C984.369 922.489 906.108 871.374 857.405 792.493C776.092 661.024 855.827 572.362 940.295 478.546L946.606 471.499C978.163 436.37 1006.56 408.394 1034.02 380.733C1104.39 310.581 1160.25 255.154 1212.84 138.83L1222.41 143.142C1168.98 261.78 1112.81 317.838 1041.59 388.726C1014.24 415.966 985.947 444.153 954.706 478.966L948.289 486.118C862.98 580.776 789.347 662.707 866.556 787.55C913.997 864.328 991.312 914.917 1066.42 963.718C1165.61 1028.51 1268.17 1095.61 1302.46 1224.76C1334.02 1344.56 1373.68 1565.85 1361.48 1682.69C1346.01 1829.94 1271.22 1888.94 1158.04 1977.82L1124.69 2004.21C1009.61 2094.66 947.132 2156.93 878.653 2285.03Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M850.779 2211.2L841.627 2206.05C913.577 2078.15 975.534 2019.89 1096.08 1929.65C1109.65 1919.13 1123.33 1909.56 1136.69 1899.88C1239.35 1825.1 1328.03 1760.63 1331.39 1628.53C1333.29 1556.38 1296.36 1460.25 1263.86 1374.74C1246.4 1329.1 1229.99 1286.08 1220.1 1249.27C1187.7 1128.84 1107.65 1056.69 1022.76 980.441C957.547 921.753 890.12 861.067 847.623 776.717C770.308 623.477 891.382 489.905 1008.35 360.75C1072.63 289.862 1133.32 222.865 1160.88 154.396L1170.66 158.288C1142.37 228.545 1081.15 296.172 1016.14 367.797C901.481 494.007 782.931 625.265 856.985 771.984C898.535 854.547 965.331 914.602 1029.81 972.553C1112.07 1046.18 1197.07 1123.06 1229.67 1246.53C1239.46 1282.82 1255.76 1325.63 1273.12 1370.96C1306.04 1456.99 1343.28 1554.59 1341.39 1628.85C1337.92 1765.57 1247.24 1832.04 1142.37 1908.4C1129.01 1918.08 1115.44 1927.86 1101.87 1938.06C983.108 2027.35 921.888 2084.99 850.779 2211.2Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M822.061 2140.21L813.12 2134.63C897.903 1997.9 971.747 1941.11 1095.03 1852.76C1105.55 1845.08 1117.44 1836.99 1129.95 1828.47C1210.11 1773.78 1319.82 1699 1320.87 1599.61C1321.5 1537.45 1273.43 1467.09 1226.94 1399.04C1194.12 1350.97 1163.09 1305.64 1149.31 1264.1C1117.75 1168.81 1054.64 1086.14 994.047 1006.31C928.619 920.386 860.981 831.513 833.842 727.81C795.553 580.565 881.073 480.649 971.747 374.422C1025.39 311.317 1080.93 246.214 1109.44 169.856L1119.33 173.538C1090.19 251.577 1034.02 317.522 979.741 381.258C890.96 485.382 807.124 583.72 844.046 725.181C870.659 826.78 934.614 910.92 1002.46 999.898C1063.79 1080.46 1127.11 1163.87 1159.3 1260.73C1172.66 1300.91 1203.17 1345.71 1235.56 1393.04C1283 1462.46 1332.02 1534.29 1331.39 1599.71C1330.23 1704.89 1217.89 1781.24 1135.95 1837.09C1123.43 1845.61 1111.65 1853.71 1101.13 1861.28C979.005 1948.79 905.792 2004.95 822.061 2140.21Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M791.451 2074.89L781.879 2070.58C811.764 2003.48 854.98 1943.16 908.904 1893.27C962.827 1843.39 1026.33 1804.99 1095.56 1780.4C1222.94 1735.39 1290.16 1648.51 1295.42 1521.99C1282.37 1470.56 1239.46 1440.69 1194.01 1408.92C1148.57 1377.16 1103.45 1345.82 1080.3 1290.81V1289.13C1073.62 1180.05 1032.47 1075.9 962.806 991.695C888.655 898.604 834.575 791.176 803.969 676.17C791.241 552.589 864.664 462.244 935.667 374.843C983.844 315.84 1033.39 254.944 1057.48 183.845L1068 187.316C1042.75 260.202 992.785 321.73 944.398 381.259C871.501 470.973 802.601 555.639 814.803 674.066C845.072 787.419 898.432 893.298 971.537 985.069C1042.13 1070.83 1083.8 1176.7 1090.61 1287.55C1112.7 1339.3 1157.2 1370.32 1200.22 1400.3C1247.56 1433.32 1292.26 1464.46 1306.04 1520.2V1520.94V1521.67C1300.68 1653.35 1231.04 1743.7 1099.03 1790.29C1031.16 1814.44 968.91 1852.12 916.04 1901.04C863.17 1949.96 820.784 2009.11 791.451 2074.89V2074.89Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M757.159 2020.62L747.692 2016.1C812.91 1878.64 920.309 1783.45 1066.84 1733.07C1195.8 1691.85 1263.97 1606.86 1269.54 1480.65C1251.24 1429.12 1204.74 1416.08 1155.51 1402.19C1103.97 1387.68 1045.59 1371.17 1014.14 1307.54L1013.51 1305.43C1010.15 1195.03 977.475 1087.51 918.837 993.904C856.322 891.484 812.28 778.885 788.716 661.235C780.09 543.229 838.261 464.768 899.902 381.68C941.452 325.622 984.054 267.565 1006.14 195.836L1016.66 198.886C994.468 272.508 950.92 331.091 908.738 388.201C848.359 469.606 791.241 546.594 799.551 659.973C822.844 776.056 866.238 887.178 927.778 988.33C987.273 1083.11 1020.49 1192.01 1024.03 1303.85C1053.59 1362.86 1106.81 1377.48 1158.35 1392.31C1209.9 1407.14 1259.97 1421.02 1279.85 1478.45V1479.39V1480.44C1274.38 1611.81 1203.8 1700.15 1069.99 1743.38C926.515 1792.39 821.22 1885.79 757.159 2020.62Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M718.028 1980.34L708.771 1975.5C784.193 1831.62 902.322 1732.97 1059.69 1682.48C1198.43 1639.05 1252.82 1556.27 1225.99 1430.06C1171.02 1400.99 1107.17 1393.49 1046.96 1409.03H1046.12H1045.28C1009.62 1406.51 984.054 1395.78 969.223 1376.95C954.391 1358.12 950.919 1334.36 956.074 1303.33C947.802 1221.36 928.41 1140.9 898.43 1064.16C845.334 945.05 802.301 821.705 769.782 695.417C751.268 558.69 804.389 476.758 860.666 389.778C896.01 335.193 932.616 278.608 954.18 204.986L964.699 207.931C942.609 283.026 905.688 340.241 870.028 395.563C814.803 480.86 762.734 561.424 780.616 693.419C812.98 818.99 855.802 941.632 908.633 1060.06C939.114 1138.01 958.826 1219.74 967.224 1303.01V1303.85C962.385 1332.57 966.067 1354.86 977.743 1370.22C989.419 1385.58 1013.3 1395.88 1045.49 1398.3C1108.86 1382.42 1175.9 1390.99 1233.25 1422.28L1235.35 1423.44V1425.75C1264.39 1557.64 1206.11 1647.36 1062.42 1692.48C908.212 1742.12 792.187 1838.99 718.028 1980.34Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M673.007 1957.73L663.75 1952.89C739.593 1808.59 858.563 1710.99 1017.51 1663.45C1157.2 1621.38 1212.32 1538.92 1185.81 1411.97C1124.07 1387.27 1055.31 1386.6 993.101 1410.08H991.523C953.444 1411.34 926.305 1402.93 910.632 1384.94C894.959 1366.96 891.382 1342.87 896.852 1310.48C887.576 1227.82 870.207 1146.27 844.993 1067C800.029 942.997 763.032 816.249 734.228 687.529C718.66 554.378 766.521 474.34 817.118 389.674C848.675 336.35 881.81 281.343 902.112 209.193L912.631 212.033C891.593 285.656 858.563 341.188 826.48 395.037C776.725 478.126 729.81 556.692 744.957 685.846C773.698 813.975 810.519 940.156 855.197 1063.64C880.734 1143.86 898.35 1226.4 907.792 1310.06V1311.53C902.638 1341.3 906.424 1364.12 918.942 1378C931.46 1391.89 956.495 1400.62 990.787 1399.04C1056.12 1374.61 1128.27 1375.81 1192.75 1402.4L1195.38 1403.46L1196.01 1406.19C1224.84 1539.13 1166.03 1628.74 1020.98 1672.6C864.348 1720.45 747.482 1816.06 673.007 1957.73Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M622.831 1955.31L613.575 1950.47C688.996 1806.8 807.335 1709.94 965.226 1662.61C1103.45 1620.54 1158.99 1537.66 1134.69 1410.82C1071.65 1386.57 1001.86 1386.57 938.823 1410.82H937.245C899.061 1412.39 871.712 1404.19 855.723 1386.31C839.734 1368.43 835.737 1344.24 840.786 1311.74C829.667 1229.29 812.617 1147.74 789.768 1067.74C750.427 943.209 716.135 815.316 688.786 687.529C673.954 555.113 720.343 472.235 769.151 384.414C798.394 331.827 828.584 278.187 848.675 209.824L859.194 212.874C838.156 282.289 808.282 336.77 778.829 389.568C730.651 475.811 685.209 557.217 699.515 685.846C727.075 812.687 760.946 940.369 800.287 1064.48C823.327 1145.28 840.518 1227.63 851.726 1310.9V1312.37C846.992 1342.24 851.094 1364.96 863.928 1379.05C876.761 1393.15 901.901 1401.46 936.404 1400.09C1002.62 1374.93 1075.89 1375.6 1141.63 1401.98L1144.26 1403.03L1144.89 1405.77C1171.4 1538.71 1112.18 1628.42 968.697 1672.49C813.121 1719.19 696.885 1814.37 622.831 1955.31Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M581.176 1964.35L571.814 1959.52C605.588 1891.86 652.887 1831.85 710.786 1783.21C768.684 1734.56 835.951 1698.31 908.423 1676.7C1040.65 1633.05 1093.98 1551.75 1071.47 1428.07C1011.8 1399.16 943.789 1392.41 879.601 1409.03H878.864H878.654C841.732 1407.77 815.54 1397.57 799.235 1378.84C782.931 1360.12 779.039 1336.77 783.246 1304.28C770.518 1223.92 754.74 1142.83 736.542 1063.42C704.143 941.632 675.532 817 651.548 693.209C638.61 563.738 680.16 472.341 724.024 374.423C750.201 319.741 772.042 263.087 789.347 204.986L799.866 207.826C782.504 266.458 760.484 323.61 734.017 378.735C690.679 474.76 649.865 565.526 662.383 691.631C686.261 814.791 714.978 939.213 747.166 1060.9C765.574 1140.94 781.353 1222.55 794.186 1303.43V1304.91C790.294 1334.15 794.817 1356.76 807.651 1372.01C820.484 1387.26 845.309 1397.25 878.654 1398.51C945.649 1381.44 1016.54 1388.9 1078.52 1419.55L1080.93 1420.7V1423.33C1105.55 1552.7 1048.54 1641.26 911.368 1686.27C840.219 1707.55 774.199 1743.21 717.4 1791.04C660.602 1838.88 614.236 1897.87 581.176 1964.35V1964.35Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M549.093 1981.29L539.626 1976.76C569.219 1911.93 612.078 1854.03 665.436 1806.78C718.795 1759.53 781.468 1724 849.411 1702.47C973.431 1656.61 1022.45 1576.26 999.307 1456.78C944.657 1420.53 879.271 1404.02 813.962 1409.98H812.595C744.326 1398.2 713.505 1359.49 720.974 1295.23C657.229 1164.71 638.61 912.918 626.303 746.11C625.251 730.86 624.094 716.346 623.042 702.778C613.364 576.568 645.132 471.393 678.688 359.908C694.361 307.846 710.245 254.102 722.552 197.412L733.071 199.516C720.974 256.626 704.775 310.686 688.996 362.958C655.651 473.602 624.199 578.041 633.771 701.937C634.823 715.504 635.875 730.019 637.032 745.374C649.445 911.971 668.063 1163.66 731.703 1292.28L732.334 1293.65V1295.23C724.971 1354.55 751.9 1388.73 814.488 1400.4C882.665 1394.35 950.852 1411.92 1007.62 1450.15L1009.51 1451.31V1453.41C1034.55 1578.26 981.951 1665.66 853.303 1713.41C786.689 1734.32 725.199 1768.97 672.801 1815.11C620.403 1861.25 578.261 1917.86 549.093 1981.29V1981.29Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M524.689 2004.43L514.906 2000.43C538.683 1939.25 575.561 1884.01 622.952 1838.58C670.344 1793.16 727.101 1758.66 789.242 1737.49C902.742 1688.27 945.87 1608.65 920.625 1494.33C871.528 1449 809.477 1420.17 743.169 1411.87H742.117C678.266 1388.94 649.444 1345.19 656.387 1281.87C610.103 1164.81 607.578 982.019 605.475 834.984C604.843 791.863 604.318 750.844 602.74 715.085C596.849 582.144 615.257 450.044 633.034 322.361C639.241 277.872 645.657 231.911 651.022 186.37L661.541 187.632C656.176 233.173 649.76 279.24 643.553 324.36C626.197 450.885 607.368 582.564 613.259 714.875C614.837 750.95 615.362 791.757 615.994 835.089C618.097 981.703 620.622 1164.18 666.59 1279.45V1280.72V1281.98C659.753 1341.19 685.314 1380.42 744.852 1401.98C813.641 1410.67 877.943 1440.81 928.619 1488.12L929.881 1489.28V1490.86C956.81 1610.02 910.526 1696.47 792.503 1747.69C731.857 1768.4 676.468 1802.11 630.209 1846.46C583.95 1890.8 547.937 1944.71 524.689 2004.43V2004.43Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M505.965 2031.98L495.446 2029.04C509.69 1975.14 536.632 1925.44 574.02 1884.09C611.408 1842.73 658.154 1810.93 710.35 1791.34C806.283 1746.01 852.252 1669.76 847.097 1565.11C832.791 1497.69 780.407 1464.88 724.971 1430.06C667.222 1393.88 607.474 1356.44 586.12 1276.19C555.194 1160.5 566.45 1015.15 577.389 874.635C582.649 807.849 587.908 744.744 587.908 687.949C587.908 607.596 584.963 526.19 582.123 447.519C578.862 357.7 575.496 264.83 576.548 173.118H587.067C586.015 264.62 589.381 357.384 592.642 446.573C595.482 525.454 598.427 606.86 598.427 687.424C598.427 744.639 593.378 807.954 587.908 874.951C577.389 1014.52 565.818 1158.92 596.218 1272.93C616.52 1349.08 674.585 1385.47 730.546 1420.18C786.508 1454.89 842.259 1490.22 857.511 1562.8C863.402 1673.02 815.435 1752.11 714.978 1800.07C664.111 1819.16 618.575 1850.2 582.202 1890.55C545.829 1930.91 519.683 1979.41 505.965 2031.98Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M490.817 2062.59L480.298 2060.69C499.232 1956.68 556.876 1881.27 651.758 1836.78C734.017 1787.97 770.307 1714.25 758.947 1618.01C746.534 1542.29 695.833 1496.11 642.501 1447.31C587.066 1396.62 529.737 1344.24 516.273 1255.68C499.863 1150.51 523.11 1038.39 545.726 929.641C561.189 854.967 577.283 777.769 579.492 705.303C582.858 594.975 559.822 487.065 537.416 382.731C520.127 308.729 507.132 233.79 498.496 158.288L509.015 157.341C517.565 232.424 530.491 306.945 547.725 380.523C570.13 485.698 593.272 594.028 589.801 705.619C587.487 779.241 571.393 856.65 555.824 931.85C533.419 1039.55 510.277 1150.82 526.476 1253.9C539.73 1338.88 595.692 1389.99 649.865 1439.42C702.46 1487.38 756.527 1536.92 769.571 1616.43C781.458 1717.51 743.484 1794.6 656.912 1845.93C613.196 1865.1 575.041 1895.02 546.001 1932.9C516.96 1970.78 497.976 2015.4 490.817 2062.59V2062.59Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M476.932 2095.82L466.939 2092.56C486.715 2030.82 527.739 1978.13 567.501 1927.23C628.406 1848.98 685.945 1775.04 669.851 1673.02C657.018 1591.3 607.894 1529.77 560.348 1470.24C506.701 1403.03 451.266 1333.51 445.586 1234.33C439.905 1135.15 475.46 1042.49 509.752 952.78C538.048 879.157 567.291 802.484 572.866 723.498C581.807 601.495 535.839 481.596 491.343 366.429C463.363 293.543 434.33 218.132 419.499 143.037L430.018 141.039C444.639 214.661 473.461 290.177 501.337 362.643C546.253 479.282 592.642 599.917 583.49 724.339C577.599 804.798 548.146 882.102 519.534 956.566C485.663 1045.23 450.53 1137.05 456.42 1233.7C462.311 1330.36 516.379 1397.78 568.974 1463.61C617.361 1524.2 667.432 1586.88 680.686 1671.34C697.516 1777.98 635.875 1857.07 576.232 1933.64C536.575 1983.92 496.182 2035.87 476.932 2095.82Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M463.152 2127.27L452.633 2125.48C463.678 2064.06 491.448 2012.42 518.167 1962.57C552.143 1899.46 584.226 1839.72 583.7 1762.73C583.069 1663.55 532.473 1578.99 483.559 1497.17C432.121 1411.24 378.895 1322.37 382.261 1215.51C385.206 1119.59 429.281 1040.81 471.778 964.56C505.649 903.664 540.783 840.874 554.878 769.566C582.754 630.735 510.488 490.747 440.957 355.597C402.247 280.396 362.169 202.672 341.342 127.682L351.861 124.843C372.268 198.465 410.347 272.614 450.634 350.864C521.112 487.591 594.114 628.947 565.713 771.564C551.196 844.45 515.642 908.292 481.56 969.503C437.696 1048.17 396.356 1122.43 393.411 1215.72C390.15 1319.42 442.535 1407.03 493.237 1491.7C542.886 1574.68 594.219 1660.5 594.85 1762.52C595.376 1842.14 561.189 1905.77 528.055 1967.4C501.126 2016.73 473.882 2067.53 463.152 2127.27Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M436.855 2157.03C432.226 2093.09 449.793 2036.5 466.729 1981.81C482.823 1929.86 499.443 1876.64 497.129 1816.16C493.237 1715.82 448.321 1621.69 404.877 1530.61C354.701 1425.44 302.842 1316.79 314.939 1198.68C324.932 1101.29 380.157 1032.82 433.594 966.557C477.353 912.287 522.69 856.229 542.887 784.289C582.122 644.722 483.664 489.799 388.572 339.189C337.765 258.94 289.693 183.214 265.184 113.167L275.072 109.697C299.266 178.586 346.917 253.892 397.408 333.614C493.868 485.803 593.483 643.144 552.985 787.129C531.947 860.751 486.189 918.072 441.799 973.184C386.889 1041.23 335.03 1105.49 325.353 1199.73C313.572 1315.42 362.59 1417.55 414.449 1525.77C458.313 1617.8 503.756 1712.88 507.648 1815.43C509.962 1877.27 493.131 1931.85 476.722 1984.55C459.365 2040.92 442.851 2094.03 447.374 2155.98L436.855 2157.03Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M415.817 2183.85C411.083 2127.18 410.169 2070.26 413.082 2013.47C416.701 1949.73 414.871 1885.8 407.612 1822.37C396.147 1742.43 362.38 1657.14 326.616 1566.79C274.021 1434.8 220.268 1298.39 239.308 1176.07C254.771 1076.26 324.302 1019.04 391.518 963.718C440.116 923.752 490.397 882.418 519.745 825.203C590.854 686.792 452.844 494.006 331.665 324.464C271.812 241.586 215.956 163.651 190.71 100.336L200.493 96.5498C225.107 158.603 280.753 236.222 339.659 318.364C463.153 490.641 603.161 686.477 529.002 830.04C498.602 889.149 447.585 931.114 398.145 971.816C329.351 1028.4 264.448 1081.83 249.511 1177.64C230.998 1297.23 284.54 1432.27 336.293 1562.9C372.268 1653.88 406.35 1739.7 417.921 1820.89C425.259 1884.92 427.124 1949.45 423.496 2013.79C420.58 2070.09 421.494 2126.52 426.231 2182.7L415.817 2183.85Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M388.783 2205.1C382.576 2138 369.428 2069.95 356.7 2004.22C347.443 1956.36 337.765 1906.93 330.928 1858.02C320.409 1780.51 288.852 1692.16 257.295 1598.56C204.7 1446.79 149.79 1289.87 175.352 1162.08C196.39 1058.17 274.441 1008.94 350.178 961.3C404.877 926.907 461.364 891.463 496.077 835.089C564.661 724.866 492.816 630.524 423.39 539.232C413.608 526.296 403.825 513.57 394.673 500.843C367.219 463.086 340.816 428.904 315.255 395.668C240.99 299.749 176.824 216.871 121.494 89.5038L131.172 85.402C185.976 211.612 249.721 293.859 323.565 389.253C349.126 422.383 375.634 456.67 403.194 494.428C412.345 506.943 422.023 519.67 431.806 532.501C500.074 622.215 577.389 723.919 505.439 840.348C469.464 898.3 411.924 934.48 356.279 969.398C278.964 1017.99 205.962 1064.06 186.081 1163.45C161.151 1288.39 215.219 1443.95 267.604 1594.66C300.318 1688.8 330.718 1777.56 342.183 1856.13C349.021 1904.72 358.593 1954.15 367.85 2001.8C381.978 2068.52 392.721 2135.92 400.038 2203.73L388.783 2205.1Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M353.438 2219.4C350.177 2157.03 329.35 2100.56 307.26 2040.61C288.851 1990.54 269.707 1938.69 261.712 1882.84C251.193 1807.75 222.897 1718.88 193.023 1624.74C140.428 1457.52 85.3081 1284.71 114.446 1152.61C138.429 1044.28 222.897 1001.69 304.525 960.458C363.957 930.483 425.388 899.562 463.783 843.398C538.257 734.016 457.366 634.205 385.942 546.279C374.371 532.08 363.536 518.618 353.333 505.26C322.617 465.084 293.059 429.114 264.552 394.301C184.923 297.435 116.234 213.82 56.9067 82.9827L66.4791 78.6705C125.175 208.141 193.549 291.334 272.652 387.675C301.264 422.488 330.928 458.563 361.643 498.845C372.162 512.097 382.681 525.454 394.042 539.653C467.675 630.524 551.827 733.49 472.408 849.288C432.541 907.765 369.953 939.318 309.363 969.819C226.158 1011.89 147.581 1051.33 124.755 1154.93C96.1428 1284.19 150.526 1455.62 203.016 1621.48C232.995 1716.14 261.291 1805.43 272.126 1881.37C279.91 1936.17 298.844 1987.49 317.147 2037.13C338.186 2094.98 360.591 2154.83 363.957 2218.77L353.438 2219.4Z"
        fill="white"
        fillOpacity="0.09"
      />
      <path
        d="M318.411 2225.08H307.892C307.892 2182.28 293.902 2157.24 278.754 2130.42C270.775 2116.91 263.745 2102.85 257.716 2088.35C230.262 2019.15 215.114 1981.08 201.965 1894.94C190.815 1822.16 164.833 1735.18 137.273 1643.04C86.9923 1474.76 30.0844 1285.45 59.0118 1148.72C83.2055 1035.34 170.934 997.163 255.823 960.247C317.99 933.217 382.051 905.346 422.444 848.025C499.128 738.854 414.765 637.675 340.291 547.961C328.615 533.867 317.464 520.615 307.261 507.468C274.336 465.398 242.569 428.061 211.958 391.776C130.226 295.856 59.7481 212.978 0 79.7216L9.57227 75.4094C68.6892 207.299 138.746 289.651 219.953 384.939C250.668 421.12 282.436 458.562 315.571 500.632C326.09 513.569 336.609 526.821 348.39 540.809C421.392 628.63 512.277 737.907 431.07 853.6C388.994 913.339 323.565 941.947 260.03 969.292C173.774 1006.84 92.357 1042.18 69.3204 1150.3C40.919 1283.77 97.5114 1472.55 147.372 1639.15C175.037 1731.6 201.124 1818.9 212.379 1892.41C225.423 1977.39 239.413 2012.84 267.499 2083.52C273.509 2097.71 280.54 2111.46 288.537 2124.64C303.789 2153.14 318.727 2179.44 318.411 2225.08Z"
        fill="white"
        fillOpacity="0.09"
      />
    </svg>
  );
};

export default OndasBackground;
