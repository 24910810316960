import { ReactNode } from 'react';
import './button.css';
import useContextState from '../../hooks/useContextState';

interface ButtonProps {
  to: string;
  children?: ReactNode;
}

const ButtonLink = ({ to, children }: ButtonProps) => {
  const { app } = useContextState();
  const { handleGoTo } = app as any;

  const BorderButton = () => (
    <svg
      viewBox="0 0 157 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="button__border"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117 0H11C4.92487 0 0 4.92487 0 11V30.0615C0 36.1367 4.92487 41.0615 11 41.0615H146C152.075 41.0615 157 36.1367 157 30.0615V11C157 4.92487 152.075 0 146 0H139V1H146C151.523 1 156 5.47715 156 11V30.0615C156 35.5844 151.523 40.0615 146 40.0615H11C5.47715 40.0615 1 35.5844 1 30.0615V11C1 5.47716 5.47715 1 11 1H117V0Z"
        fill="white"
      />
    </svg>
  );
  return (
    <button className="button" onClick={() => handleGoTo(to)}>
      <BorderButton />
      {children}
    </button>
  );
};

export default ButtonLink;
