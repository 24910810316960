import { useContext } from 'react';
import { appContext } from '../context/appState';

const useContextState = () => {
  const appState = useContext(appContext as any);

  return {
    app: appState,
  };
};

export default useContextState;
