import Logo from '../Logo/Logo';
import './header.css';

const Header = () => {
  return (
    <header className="header">
      <section className="header__logo">
        {' '}
        <Logo />
      </section>
    </header>
  );
};

export default Header;
