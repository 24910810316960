import { useState, createContext } from 'react';

export const appContext = createContext<any>(null);

const AppState = ({ children }: any) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [currentPath, setCurrentPath] = useState('/' as string);

  const handleGoTo = (path: string) => {
    setCurrentPath(path);
    setOpenMenu(false);
  };

  return (
    <appContext.Provider
      value={{
        openMenu,
        currentPath,
        setOpenMenu: setOpenMenu,
        handleGoTo,
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export default AppState;
