/* eslint-disable react/no-unescaped-entities */
import Button from '../../components/Button/Button';
import Service from '../../components/Service/Service';
import './home.css';

const Home = () => {
  const services = ['WEB SOLUTIONS', 'MOVIL APPS', 'UI/UX DESIGN'];
  return (
    <section className="home">
      <section className="section">
        <Button>ABOUT ME</Button>
        <p className="max-w-lg">
          I am an independent creator who develops software products under the
          Indie Hacker philosophy, I provide customized solutions to startups
          and emerging companies, I like to experiment with new ideas and bring
          them to reality by creating intuitive and easy to use products that
          can be tested in the market quickly. With my agile and focused
          approach, I can help you create a solution that perfectly fits your
          business needs and your users' expectations. Contact me and let's make
          your project a reality.
        </p>
      </section>
      <section className="section">
        <Button>SERVICES</Button>
        <section className="section-services">
          {services.map((service) => (
            <Service key={service} service={service} />
          ))}
        </section>
      </section>
    </section>
  );
};

export default Home;

const OldHome = () => {
  const services = ['WEB SOLUTIONS', 'MOVIL APPS', 'UI/UX DESIGN'];
  return (
    <section className="home">
      <section className="section">
        <Button>ABOUT US</Button>
        <p>
          We are a digital agency that helps startups and technology companies
          to conceive, design and transform their ideas into mobile and web
          applications
        </p>
      </section>
      <section className="section">
        <Button>SERVICES</Button>
        <section className="section-services">
          {services.map((service) => (
            <Service key={service} service={service} />
          ))}
        </section>
      </section>
    </section>
  );
};
