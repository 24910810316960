import './assets/App.css';
import Layout from './components/Layout/Layout';
import Home from './views/Home/Home';
import AppState from './context/appState';
import 'react-toastify/dist/ReactToastify.css';
import AppRouting from 'routing/AppRouting';

function App() {
  return (
    <AppState>
      <AppRouting />
    </AppState>
  );
}

export default App;
