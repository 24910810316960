import Layout from 'components/Layout/Layout';
import BootcampDoc from 'pages/BootcampDoc/BootcampDoc';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const AppRouting = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/bootcamp-doc" element={<BootcampDoc />} />
      </Routes>
    </Router>
  );
};

export default AppRouting;
