import React, { useEffect, useRef, useState } from 'react';
import './doc.css';

interface Props {
  section1?: React.ReactNode;
  section2?: React.ReactNode;
  section3?: React.ReactNode;
}

const NavigationMenu: React.FC<Props> = ({ section1, section2, section3 }) => {
  const [activeSection, setActiveSection] = useState('#section-1');
  const sectionContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const sectionContainer = sectionContainerRef.current;
      if (!sectionContainer) return;

      let activeSectionId = '';

      for (let i = 0; i < sectionContainer.children.length; i++) {
        const section = sectionContainer.children[i] as HTMLElement;
        if (section.offsetTop <= window.scrollY + 1) {
          activeSectionId = `#${section.id}`;
        }
      }

      setActiveSection(activeSectionId);
      window.history.pushState(null, '', activeSectionId);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const sectionId = event.currentTarget.getAttribute('href') || '';
    setActiveSection(sectionId);
    window.history.pushState(null, '', sectionId);
  };

  const links = [
    {
      id: '#install-vscode',
      label: 'Install VSCode',
    },
    {
      id: '#section-2',
      label: 'Sección 2',
    },
    {
      id: '#section-3',
      label: 'Sección 3',
    },
  ];

  return (
    <div className="flex h-screen bootcamp-doc">
      <div className="w-1/4  ">
        <ul className="list-none fixed bg-gray-800 p-4 w-1/4 text-white h-full top-0 left-0">
          {links.map((link) => (
            <li
              key={link.id}
              className={`mb-4 ${activeSection === link.id ? 'font-bold' : ''}`}
            >
              <a href={link.id} onClick={handleLinkClick}>
                {link.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="w-3/4 p-4 sections divide-y" ref={sectionContainerRef}>
        <section id="install-vscode">
          <h1>Instalar Visual Studio Code</h1>
          <a
            href="https://code.visualstudio.com/"
            target="_blank"
            rel="noreferrer"
          >
            Visitar Sitio Oficial
          </a>
        </section>
        <section id="install-vscode">
          <h1>Diseño Figma</h1>
          <a
            href="https://code.visualstudio.com/"
            target="_blank"
            rel="noreferrer"
          >
            Figma
          </a>
        </section>
        <section id="section-3">
          <Content />
          <Content />
          <Content />
          <Content />
          <Content />
          <Content />
          <Content />
          <Content />
          <Content />
          <Content />
          <Content />
        </section>
      </div>
    </div>
  );
};

export default NavigationMenu;

const Content = () => (
  <p>
    sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd
    sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd
    sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd
    sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd
    sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd
    sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd
    sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd
    sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd
    sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd
    sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd
    sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd
    sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd sjdlkasjdklasjdklasd
  </p>
);
