import * as yup from 'yup';

export const registerValidation = yup.object().shape({
  name: yup.string().required('Nombre requerido'),
  lastName: yup.string().required('Apellido requerido'),
  email: yup
    .string()
    .email('Ingresa un email válido')
    .required('Correo requerido'),
  phoneNumber: yup.string().required('Teléfono requerido'),
});

// export const registerValidation = yup.object().shape({
//   email: yup.string().email().required(),
//   password: yup.string().min(6).required(),
//   confirmPassword: yup
//     .string()
//     .oneOf([yup.ref('password'), null], 'Passwords must match'),
// });
