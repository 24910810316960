import { useEffect, useRef, useState } from 'react';
import useContextState from '../../hooks/useContextState';
import Menu from '../Menu/Menu';
import routes from '../../routing/routes';

interface Props {
  children: React.ReactNode;
}

const MainContent = ({ children }: Props) => {
  const mainContainer = useRef<HTMLElement>(null);
  const contentRef = useRef<HTMLElement>(null);
  const menuRef = useRef<HTMLElement>(null);
  const { app } = useContextState();
  const { openMenu, currentPath } = app as any;

  // const handleResize = () => {
  //   const main = mainContainer.current;
  //   const content = contentRef.current;
  //   const menu = menuRef.current;
  //   if (main && content && menu) {
  //     const clientHeight = main.clientHeight;
  //     const isOpen = menu.style.top === '0px';
  //     menu.style.top = isOpen ? '0px' : `-${clientHeight}px`;
  //     content.style.top = isOpen ? `${clientHeight}px` : '0px';
  //     menu.style.height = `${clientHeight}px`;
  //     content.style.height = `${clientHeight}px`;
  //   }
  // };

  useEffect(() => {
    const main = mainContainer.current;
    const content = contentRef.current;
    const menu = menuRef.current;
    if (main && content && menu) {
      menu.style.top = openMenu ? '0px' : `-${main.clientHeight}px`;
      content.style.top = openMenu ? `${main.clientHeight}px` : '0px';
    }
  }, [openMenu]);

  const currentRoute =
    routes?.find?.((route) => route.path === currentPath) || null;

  const innerClass =
    'absolute inset-0 w-full transition-all duration-500 ease-in-out';

  return (
    <section
      className="layout__content relative overflow-hidden"
      ref={mainContainer}
    >
      <section
        className={`${innerClass} ${openMenu ? 'delay-300' : ''} h-[80vh]`}
        ref={menuRef}
      >
        <section className="w-full h-full py-2 px-1 block">
          <Menu />
        </section>
      </section>
      <section
        className={`${innerClass} ${!openMenu ? 'delay-300' : ''} h-[80vh]`}
        ref={contentRef}
      >
        <section className="w-full h-full px-1 overflow-y-auto">
          {currentRoute?.component}
        </section>
      </section>
    </section>
  );
};

export default MainContent;
