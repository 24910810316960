import Burguer1 from './Burguer1';
import Burguer2 from './Burguer2';
import './burguerButton.css';
import useContextState from '../../hooks/useContextState';

const BurguerButton = () => {
  const { app } = useContextState();
  const { openMenu, setOpenMenu } = app as any;

  return (
    <button
      className={`burguer-button__button ${openMenu ? 'open' : ''}`}
      onClick={() => setOpenMenu(!openMenu)}
    >
      <section>
        <div className="burguer-button__line1">
          <Burguer1 />
        </div>
        <div className="burguer-button__line2 mt-2">
          <Burguer2 />
        </div>
      </section>
    </button>
  );
};

export default BurguerButton;
