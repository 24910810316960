import CustomField from 'components/CustomField/CustomField';
import { Form, Formik, FormikValues } from 'formik';
import { registerValidation } from './registerValidation';
import axiosClient from 'config/axiosClient';
import { ToastContainer, toast } from 'react-toastify';
import LoadingFullScreen from 'components/Loading/LoadingFullScreen';
import { useState } from 'react';

const BootcampForm = () => {
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (data: any, { resetForm }: any) => {
    setLoading(true);
    try {
      await axiosClient.post('/auth/free-signup', data);
      setLoading(false);
      toast.success('Inscripción exitosa', {
        position: toast.POSITION.BOTTOM_CENTER,
        className: 'toast-success',
      });
      resetForm();
    } catch (error: any) {
      setLoading(false);
      const { status, data } = error.response;
      if (status !== 500) {
        toast.error(data.error, {
          position: toast.POSITION.BOTTOM_CENTER,
          className: 'toast-error',
        });
      } else {
        toast.error('Error interno del servidor', {
          className: 'toast-error',
        });
      }
    }
  };
  return (
    <section className="w-full max-w-md mx-auto text-white">
      {loading && <LoadingFullScreen />}
      <ToastContainer autoClose={3500} />
      <Formik
        initialValues={{
          name: '',
          lastName: '',
          email: '',
          phoneNumber: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={registerValidation}
      >
        {({ values, errors, touched, handleChange }: FormikValues) => (
          <>
            <section className="px-2">INSCRIPCIÓN</section>
            <Form className="grid gap-4 px-2">
              <section className="flex gap-4">
                <CustomField
                  type={'text'}
                  name={'name'}
                  label="Nombre"
                  placeholder="Ej. Juan"
                  value={values.name}
                  error={errors.name}
                  touched={touched.name}
                  onChange={handleChange}
                />
                <CustomField
                  type={'text'}
                  name={'lastName'}
                  label="Apellido"
                  placeholder="Ej. Pérez"
                  value={values.lastName}
                  error={errors.lastName}
                  touched={touched.lastName}
                  onChange={handleChange}
                />
              </section>
              <CustomField
                type={'email'}
                name={'email'}
                label="Correo electrónico"
                placeholder="Ej. juanperez@gmail.com"
                value={values.email}
                error={errors.email}
                touched={touched.email}
                onChange={handleChange}
              />
              <CustomField
                type={'text'}
                name={'phoneNumber'}
                label="Teléfono"
                placeholder="Ej. 04249999999"
                value={values.phoneNumber}
                error={errors.phoneNumber}
                touched={touched.phoneNumber}
                onChange={handleChange}
              />
              <button className="bg-dark-100 bg-opacity-90 text-dark-1000 rounded-lg p-2 shadow-lg shadow-white font-semibold text-lg hover:bg-dark-300 transition">
                Inscribirme
              </button>
            </Form>
          </>
        )}
      </Formik>
    </section>
  );
};

export default BootcampForm;
