import React from 'react';

const Logo = () => (
  <svg viewBox="0 0 250 126" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M48.9905 55.1752H22.2031V86.4116H0V4.86515H53.9061V21.4509H22.2031V38.5341H48.9905V55.1752Z"
      fill="white"
    />
    <path
      d="M90.7214 87.7937C84.0936 87.7937 78.2575 86.5037 73.213 83.9237C68.2053 81.3437 64.2839 77.7502 61.4487 73.143C58.6503 68.5359 57.2511 63.1916 57.2511 57.1102C57.2511 51.0287 58.6503 45.6844 61.4487 41.0773C64.2839 36.4701 68.2053 32.895 73.213 30.3519C78.2575 27.7719 84.0936 26.4819 90.7214 26.4819C97.3492 26.4819 103.167 27.7719 108.175 30.3519C113.219 32.895 117.141 36.4701 119.939 41.0773C122.774 45.6844 124.192 51.0287 124.192 57.1102C124.192 63.1916 122.774 68.5359 119.939 73.143C117.141 77.7502 113.219 81.3437 108.175 83.9237C103.167 86.5037 97.3492 87.7937 90.7214 87.7937ZM90.7214 70.9316C94.109 70.9316 96.7601 69.6784 98.6748 67.1722C100.626 64.6659 101.602 61.3119 101.602 57.1102C101.602 52.9084 100.626 49.5729 98.6748 47.1034C96.7601 44.634 94.109 43.3993 90.7214 43.3993C87.3339 43.3993 84.6644 44.634 82.7128 47.1034C80.7981 49.5729 79.8408 52.9084 79.8408 57.1102C79.8408 61.3119 80.7981 64.6659 82.7128 67.1722C84.6644 69.6784 87.3339 70.9316 90.7214 70.9316Z"
      fill="white"
    />
    <path
      d="M132.241 86.4116V27.6981H154.555V35.8252C156.396 32.8397 158.771 30.5361 161.679 28.9144C164.588 27.2927 167.957 26.4819 171.787 26.4819C174.18 26.4819 175.984 26.6477 177.2 26.9794C178.451 27.2743 179.225 27.5139 179.519 27.6981L176.482 47.1587C176.15 46.9744 175.414 46.698 174.272 46.3294C173.131 45.924 171.75 45.7213 170.13 45.7213C166.3 45.7213 163.244 46.5137 160.961 48.0986C158.679 49.6466 157.04 51.6369 156.046 54.0694C155.052 56.502 154.555 59.0267 154.555 61.6436V86.4116H132.241Z"
      fill="white"
    />
    <path
      d="M187.257 86.4116V0H208.355V41.5196L223.102 27.6981H250L226.195 49.2043L249.006 86.4116H225.753L212.111 61.92L208.355 65.4583V86.4116H187.257Z"
      fill="white"
    />
    <path
      d="M1.63184 119.591V99.1319H6.73879C8.52392 99.1319 10.0744 99.5434 11.3903 100.366C12.7153 101.189 13.7367 102.368 14.4544 103.903C15.1814 105.429 15.5448 107.246 15.5448 109.355C15.5448 111.463 15.1814 113.285 14.4544 114.82C13.7367 116.345 12.7153 117.524 11.3903 118.357C10.0744 119.18 8.52392 119.591 6.73879 119.591H1.63184ZM6.46274 101.212H4.00588V117.511H6.46274C7.76938 117.511 8.92419 117.182 9.92718 116.526C10.9394 115.86 11.7307 114.917 12.3012 113.696C12.8717 112.476 13.157 111.028 13.157 109.355C13.157 107.672 12.8717 106.224 12.3012 105.013C11.7307 103.792 10.9394 102.854 9.92718 102.197C8.92419 101.541 7.76938 101.212 6.46274 101.212Z"
      fill="white"
    />
    <path
      d="M46.9595 114.958C46.8951 115.744 46.5868 116.517 46.0347 117.275C45.4918 118.024 44.7097 118.648 43.6883 119.147C42.6669 119.637 41.4108 119.883 39.9202 119.883C38.4479 119.883 37.1505 119.559 36.0278 118.912C34.9052 118.264 34.0219 117.367 33.3778 116.221C32.7428 115.074 32.4254 113.756 32.4254 112.267C32.4254 110.76 32.7474 109.438 33.3916 108.3C34.0357 107.154 34.9236 106.257 36.0554 105.61C37.1873 104.962 38.4801 104.639 39.934 104.639C41.4154 104.639 42.6991 104.962 43.7849 105.61C44.8707 106.257 45.708 107.145 46.297 108.273C46.8859 109.392 47.1803 110.668 47.1803 112.101C47.1803 112.249 47.1757 112.443 47.1665 112.684C47.1573 112.924 47.1435 113.086 47.1251 113.169H34.758C34.9328 114.593 35.4803 115.707 36.4005 116.512C37.3207 117.316 38.5169 117.719 39.9892 117.719C41.029 117.719 41.8801 117.58 42.5427 117.303C43.2052 117.016 43.7021 116.66 44.0333 116.234C44.3738 115.809 44.567 115.384 44.613 114.958H46.9595ZM39.9616 106.775C38.5905 106.775 37.4403 107.158 36.5109 107.926C35.5816 108.684 35.0111 109.729 34.7994 111.061H44.6545C44.5716 109.877 44.1346 108.869 43.3432 108.037C42.5519 107.195 41.4246 106.775 39.9616 106.775Z"
      fill="white"
    />
    <path
      d="M75.9574 104.86L71.0437 119.591H67.9657L62.9416 104.86H65.3708L69.4978 117.76L73.5281 104.86H75.9574Z"
      fill="white"
    />
    <path
      d="M105.977 114.958C105.913 115.744 105.605 116.517 105.053 117.275C104.51 118.024 103.728 118.648 102.706 119.147C101.685 119.637 100.429 119.883 98.938 119.883C97.4657 119.883 96.1683 119.559 95.0457 118.912C93.9231 118.264 93.0397 117.367 92.3956 116.221C91.7607 115.074 91.4432 113.756 91.4432 112.267C91.4432 110.76 91.7653 109.438 92.4094 108.3C93.0535 107.154 93.9415 106.257 95.0733 105.61C96.2051 104.962 97.498 104.639 98.9518 104.639C100.433 104.639 101.717 104.962 102.803 105.61C103.889 106.257 104.726 107.145 105.315 108.273C105.904 109.392 106.198 110.668 106.198 112.101C106.198 112.249 106.194 112.443 106.184 112.684C106.175 112.924 106.161 113.086 106.143 113.169H93.7759C93.9507 114.593 94.4982 115.707 95.4184 116.512C96.3385 117.316 97.5348 117.719 99.007 117.719C100.047 117.719 100.898 117.58 101.561 117.303C102.223 117.016 102.72 116.66 103.051 116.234C103.392 115.809 103.585 115.384 103.631 114.958H105.977ZM98.9794 106.775C97.6084 106.775 96.4582 107.158 95.5288 107.926C94.5994 108.684 94.0289 109.729 93.8173 111.061H103.672C103.589 109.877 103.152 108.869 102.361 108.037C101.57 107.195 100.443 106.775 98.9794 106.775Z"
      fill="white"
    />
    <path d="M126.322 97.9112V119.591H124.003V97.9112H126.322Z" fill="white" />
    <path
      d="M151.5 119.855C150.027 119.855 148.725 119.526 147.594 118.87C146.471 118.204 145.588 117.298 144.943 116.151C144.309 114.995 143.991 113.682 143.991 112.212C143.991 110.742 144.309 109.438 144.943 108.3C145.588 107.163 146.471 106.271 147.594 105.623C148.725 104.976 150.027 104.652 151.5 104.652C152.972 104.652 154.269 104.976 155.392 105.623C156.524 106.271 157.407 107.163 158.042 108.3C158.686 109.438 159.008 110.742 159.008 112.212C159.008 113.682 158.686 114.995 158.042 116.151C157.407 117.298 156.524 118.204 155.392 118.87C154.269 119.526 152.972 119.855 151.5 119.855ZM151.5 117.691C152.512 117.691 153.404 117.464 154.177 117.011C154.95 116.558 155.553 115.92 155.986 115.097C156.427 114.274 156.648 113.312 156.648 112.212C156.648 111.112 156.427 110.159 155.986 109.355C155.553 108.541 154.95 107.917 154.177 107.482C153.404 107.038 152.512 106.816 151.5 106.816C150.497 106.816 149.604 107.038 148.822 107.482C148.049 107.917 147.442 108.541 147 109.355C146.568 110.159 146.351 111.112 146.351 112.212C146.351 113.312 146.568 114.274 147 115.097C147.442 115.92 148.049 116.558 148.822 117.011C149.604 117.464 150.497 117.691 151.5 117.691Z"
      fill="white"
    />
    <path
      d="M176.614 125.653V104.86H178.96V106.719C179.567 106.063 180.29 105.554 181.127 105.193C181.974 104.823 182.857 104.639 183.777 104.639C184.964 104.639 186.059 104.934 187.062 105.526C188.065 106.109 188.87 106.964 189.478 108.092C190.085 109.221 190.388 110.594 190.388 112.212C190.388 113.858 190.089 115.254 189.491 116.401C188.893 117.538 188.093 118.403 187.09 118.995C186.087 119.577 184.982 119.869 183.777 119.869C182.857 119.869 181.974 119.679 181.127 119.3C180.29 118.921 179.567 118.398 178.96 117.733V125.653H176.614ZM178.974 113.308C178.974 114.103 179.181 114.829 179.595 115.485C180.018 116.142 180.57 116.664 181.251 117.053C181.932 117.441 182.664 117.635 183.446 117.635C184.743 117.635 185.82 117.192 186.676 116.304C187.541 115.407 187.973 114.043 187.973 112.212C187.973 110.372 187.545 109.012 186.689 108.134C185.834 107.246 184.752 106.802 183.446 106.802C182.664 106.802 181.932 106.997 181.251 107.385C180.57 107.764 180.018 108.277 179.595 108.925C179.181 109.572 178.974 110.298 178.974 111.102H178.96V113.183H178.974V113.308Z"
      fill="white"
    />
    <path
      d="M221.964 114.958C221.9 115.744 221.592 116.517 221.039 117.275C220.497 118.024 219.714 118.648 218.693 119.147C217.672 119.637 216.416 119.883 214.925 119.883C213.453 119.883 212.155 119.559 211.033 118.912C209.91 118.264 209.027 117.367 208.383 116.221C207.748 115.074 207.43 113.756 207.43 112.267C207.43 110.76 207.752 109.438 208.396 108.3C209.04 107.154 209.928 106.257 211.06 105.61C212.192 104.962 213.485 104.639 214.939 104.639C216.42 104.639 217.704 104.962 218.79 105.61C219.875 106.257 220.713 107.145 221.302 108.273C221.891 109.392 222.185 110.668 222.185 112.101C222.185 112.249 222.18 112.443 222.171 112.684C222.162 112.924 222.148 113.086 222.13 113.169H209.763C209.938 114.593 210.485 115.707 211.405 116.512C212.325 117.316 213.522 117.719 214.994 117.719C216.034 117.719 216.885 117.58 217.547 117.303C218.21 117.016 218.707 116.66 219.038 116.234C219.379 115.809 219.572 115.384 219.618 114.958H221.964ZM214.966 106.775C213.595 106.775 212.445 107.158 211.516 107.926C210.586 108.684 210.016 109.729 209.804 111.061H219.659C219.576 109.877 219.139 108.869 218.348 108.037C217.557 107.195 216.429 106.775 214.966 106.775Z"
      fill="white"
    />
    <path
      d="M239.783 119.591V104.86H242.129V106.789C242.507 106.169 243.031 105.656 243.703 105.249C244.384 104.842 245.226 104.639 246.229 104.639C246.818 104.639 247.296 104.689 247.664 104.791C248.041 104.884 248.276 104.958 248.368 105.013L247.761 107.329C247.669 107.265 247.48 107.182 247.195 107.08C246.919 106.978 246.523 106.927 246.008 106.927C244.986 106.927 244.195 107.168 243.634 107.649C243.073 108.12 242.681 108.712 242.461 109.424C242.24 110.127 242.129 110.83 242.129 111.532V119.591H239.783Z"
      fill="white"
    />
  </svg>
);

export default Logo;
