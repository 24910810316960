import OndasBackground from './OndasBackground';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import './layout.css';
import BurguerButton from '../BurguerButton/BurguerButton';
import MainContent from './MainContent';
import TitlePage from '../TitlePage/TitlePage';

const Layout = ({ children }: any) => {
  return (
    <>
      <div className="layout app h-screen">
        <section className="h-full relative z-10">
          <Header />
          <section className="grid place-content-center w-full my-2">
            <BurguerButton />
            <TitlePage />
          </section>
          <MainContent>{children}</MainContent>
          {/* <Footer /> */}
        </section>
        <section className="ondas-container">
          <OndasBackground />
        </section>
      </div>
    </>
  );
};

export default Layout;
