import bootcampImg from 'assets/images/bootcamp.jpg';
import useContextState from 'hooks/useContextState';

const EventsView = () => {
  const { app } = useContextState();
  const { handleGoTo } = app as any;
  return (
    <section
      className="w-full h-full grid place-content-center text-white p-1"
      onClick={() => handleGoTo('/bootcamp-register')}
    >
      <section className="max-w-[260px] bg-dark-1000 p-2 rounded-xl">
        <img
          src={bootcampImg}
          alt="bootcamp"
          className="w-full h-auto rounded-lg h-[40vh]"
        />
      </section>
      <button className="bg-dark-900 p-2 rounded-md block font-semibold mt-2">
        Inscripción
      </button>
    </section>
  );
};

export default EventsView;
