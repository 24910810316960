import './service.css';

interface ServiceProps {
  service: string;
}

const Service = ({ service }: ServiceProps) => {
  return <span className="service-item">{service}</span>;
};

export default Service;
