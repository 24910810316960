import routes from '../../routing/routes';
import ButtonLink from '../ButtonLink/ButtonLink';

const Menu = () => {
  return (
    <section className="w-full h-full grid place-content-center gap-4">
      {routes.map(
        (route, index) =>
          route.displayOnMenu && (
            <ButtonLink key={index} to={route.path}>
              {route.label}
            </ButtonLink>
          ),
      )}
    </section>
  );
};

export default Menu;
