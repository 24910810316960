import useContextState from '../../hooks/useContextState';
import routes from '../../routing/routes';

const TitlePage = () => {
  const { app } = useContextState();
  const { currentPath, openMenu } = app as any;
  const pageTitle = routes?.find?.(
    (route: any) => route.path === currentPath,
  )?.label;
  return (
    <section className="w-full my-2 place-content-center">
      <h1 className="text-white">{openMenu ? 'Menú' : pageTitle}</h1>
    </section>
  );
};
export default TitlePage;
