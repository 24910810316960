import CustomBorder from 'components/CustomBorder/CustomBorder';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import { Field } from 'formik';

interface CustomFieldProps {
  type: string;
  name: string;
  value: string;
  placeholder?: string;
  label?: string;
  as?: string;
  error: string;
  touched: boolean;
  required?: boolean;
  onChange: (e: any) => void;
}

const CustomField = (props: CustomFieldProps) => {
  const {
    type,
    name,
    placeholder,
    label,
    as,
    value,
    error,
    touched,
    required = false,
    onChange,
  } = props;
  const isError = error && touched;
  const baseClass =
    'border-2 border-gray-300 p-2 rounded-xl resize-none bg-dark-1000 w-full';
  const className = `${baseClass} ${
    isError ? 'border-red-500' : touched ? 'border-green-700' : ''
  }`;
  return (
    <FieldWrapper
      label={label}
      error={isError ? error : null}
      required={required}
    >
      <Field
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={className}
        as={as}
        min={0}
      />
      {/* <section className="absolute inset-0">
          <CustomBorder />
        </section> */}
    </FieldWrapper>
  );
};

export default CustomField;
