interface FieldWrapperProps {
  label?: string;
  required?: boolean;
  error: string | null;
  children: JSX.Element;
}

const FieldWrapper = ({
  label = '',
  error,
  required,
  children,
}: FieldWrapperProps) => {
  return (
    <section className="w-full">
      <section className="between">
        {error ? (
          <span className="text-red-500">{error}</span>
        ) : required ? (
          <span className="text-slate-600 text-sm">Requerido</span>
        ) : (
          <label className="text-dark-400">{label}</label>
        )}
      </section>
      {children}
    </section>
  );
};

export default FieldWrapper;
